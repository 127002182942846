import queryParams from '@/services/queryParams'
import apiInstance from './index'

export default {
  async getTrademarks(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`trademarks${query}`)
  },

  async storeTrademark(data) {
    return await apiInstance.post('trademarks', data)
  },

  async getTrademarkInfo(id) {
    return await apiInstance.get(`trademarks/${id}`)
  },

  async updateTrademark(data) {
    return await apiInstance.put(`trademarks/${data.id}`, data)
  },

  async deleteTrademark(data) {
    return await apiInstance.delete(`trademarks/${data.id}`)
  },
}
