<template>
  <b-row>
    <b-col cols="12">
      <h5>
        <strong>{{ $t('clubs.intellectualProperty.trademarkRegistration') }}</strong>
      </h5>
      <span class="i-text-request-info">
        {{ $t('corporate.create.textRequest') }}
      </span>
      <hr>
    </b-col>
    <b-col md="12">
      <b-overlay
        :show="applyOverlay"
        :class="applyOverlay ? 'p-2 mb-1' : 'mb-1'"
        opacity="1"
      >
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-spinner
              type="border"
              variant="primary"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p class="pt-1">
              {{ $t('loading') }}
            </p>
          </div>
        </template>
        <b-row>
          <b-col
            v-if="!applyOverlay"
            md="12"
          >
            <validation-observer ref="trademarkRules">
              <b-form @submit.prevent="saveTrademarkForm">
                <b-row>
                  <b-col md="6">
                    <b-form-group label-for="br-request-type">
                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.requestType') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="requestType"
                        rules="required"
                      >
                        <v-select
                          id="br-request-type"
                          v-model="trademarkForm.requestTypeId"
                          :state="errors.length > 0 ? false : null"
                          :reduce="option => option.id"
                          label="name"
                          :options="requestTypes"
                          :placeholder="$t('clubs.intellectualProperty.formTrademarks.placeholder.selectRequestType')"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="br-trademark-type">
                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.trademarkType') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="trademarkType"
                        rules="required"
                      >
                        <v-select
                          id="br-trademark-type"
                          v-model="trademarkForm.trademarkTypeId"
                          :state="errors.length > 0 ? false : null"
                          :reduce="option => option.id"
                          label="name"
                          :options="trademarkTypes"
                          :placeholder="$t('clubs.intellectualProperty.formTrademarks.placeholder.selectTrademarkType')"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="br-class-number">
                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.classNumber') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="classNumber"
                        rules="required|integer|min:1|max:10"
                      >
                        <b-form-input
                          id="br-class-number"
                          v-model="trademarkForm.classNumber"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.intellectualProperty.formTrademarks.placeholder.enterClassNumber')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label-for="br-class-description">
                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.classDescription') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="classDescription"
                        rules="required|min:5|max:255|clubNameRegex"
                      >
                        <b-form-textarea
                          id="br-class-description"
                          v-model="trademarkForm.classDescription"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('clubs.intellectualProperty.formTrademarks.placeholder.enterClassDescription')"
                          rows="2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Imagen de la marca -->
                  <b-col sm="12">
                    <b-form-group label-for="tr-image">
                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.representativeImageOfTheTrademark') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="representativeImageOfTheTrademark"
                        :rules="actions.name == 'edit' ? '' : 'required'"
                      >
                        <b-form-input
                          id="tr-image"
                          v-model="updated"
                          :state="errors.length > 0 ? false : null"
                          style="display: none"
                        />
                        <p>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </p>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    md="8"
                    lg="4"
                    class="mb-2 text-center i-display-elements offset-md-2 offset-lg-4"
                  >
                    <!-- cropper + imagen -->
                    <cropper
                      v-if="croppedImage"
                      :src="croppedImage"
                      :stencil-props="{ handlers: {}, movable: false, scalable: true, }"
                      image-restriction="stencil"
                      class="cropper"
                      @change="change"
                    />
                    <b-img
                      v-else
                      :src="trademarkForm.imageFile"
                      alt="trademark image"
                      class="i-demo-img i-shadow"
                    />
                    <!--/ cropper + imagen -->
                    <!-- cargar imagen -->
                    <p class="float-right i-translate-avatar-cl">
                      <span v-if="!croppedImage">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="btn-icon rounded-circle"
                          pill
                          variant="primary"
                          :style="colorPrimaryBtn"
                          @click="$refs.refInputCover.click()"
                        >
                          <i class="icon-0-icons-dark-image text-center" />
                          <span class="d-none d-md-inline"> {{ $t('generic.uploadImage') }}</span>
                        </b-button>
                        <input
                          ref="refInputCover"
                          type="file"
                          class="d-none"
                          accept=".jpg, .png, .gif"
                          @input="inputImageRenderer"
                        >
                      </span>
                      <span v-else>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="btn-icon rounded-circle"
                          pill
                          variant="secondary"
                          @click="cancelAction"
                        >
                          <i class="icon-0-icons-dark-cross" />
                          <span class="d-none d-md-inline"> {{ $t('buttons.cancel') }} </span>
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="btn-icon rounded-circle ml-1"
                          pill
                          variant="primary"
                          :style="colorPrimaryBtn"
                          @click="assignCover"
                        >
                          <i class="icon-0-icons-dark-floppy" />
                          <span class="d-none d-md-inline"> {{ $t('buttons.save') }} </span>
                        </b-button>
                      </span>
                    </p>
                    <!--/ cargar imagen -->
                  </b-col>
                  <!--/ Imagen de la marca -->
                  <b-col md="6">
                    <b-form-group label-for="br-denomination">
                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.denomination') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="denomination"
                        rules="required|min:1|max:255|clubNameRegex"
                      >
                        <b-form-input
                          id="br-denomination"
                          v-model="trademarkForm.denomination"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.intellectualProperty.formTrademarks.placeholder.enterDenomination')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="br-file-number">
                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.registerNumber') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="registerNumber"
                        rules="required|integer|min:1|max:20"
                      >
                        <b-form-input
                          id="br-file-number"
                          v-model="trademarkForm.registerNumber"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.intellectualProperty.formTrademarks.placeholder.enterRegisterNumber')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group for="br-date-of-concession">
                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.dateOfConcession') }}
                      <b-form-datepicker
                        id="br-date-of-concession"
                        v-model="trademarkForm.dateOfConcession"
                        hide-header
                        :locale="$i18n.locale"
                        :placeholder="$t('clubs.intellectualProperty.formTrademarks.placeholder.selectDateOfConcession')"
                        :max="todayTmk"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group for="br-termination-date">
                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.effectiveDate') }}
                      <b-form-datepicker
                        id="br-termination-date"
                        v-model="trademarkForm.effectiveDate"
                        hide-header
                        :locale="$i18n.locale"
                        :placeholder="$t('clubs.intellectualProperty.formTrademarks.placeholder.selectEffectiveDate')"
                        :disabled="trademarkForm.dateOfConcession == null || trademarkForm.dateOfConcession == ''"
                        :min="minDateEff"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col
                    v-if="actions.name == 'edit'"
                    md="6"
                  >
                    <upload-file
                      :title="$t('clubs.intellectualProperty.formTrademarks.labels.applicationForTheRegistration')"
                      folder="clubs"
                      :club-id="trademarkForm.clubId"
                      :file-title="trademarkForm.applicationForTheRegistrationFile ? trademarkForm.applicationForTheRegistrationFile.title : null"
                      @uploaded-file="assignFileDinamic($event, 2)"
                    />
                  </b-col>
                  <b-col
                    v-else
                    md="6"
                  >
                    <b-form-group>
                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.applicationForTheRegistration') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="applicationForTheRegistration"
                        rules="required"
                      >
                        <b-form-file
                          ref="file-application-input"
                          v-model="auxFile"
                          type="file"
                          accept="image/jpeg, image/png, image/jpg, application/pdf"
                          :browse-text="$t('buttons.explore')"
                          :placeholder="$t('generic.uploadDocument')"
                          :state="errors.length > 0 ? false:null"
                          no-drop
                          @change="fileValidation"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="br-holder-name">
                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.holderName') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="holderName"
                        rules="required|min:1|max:255|nameRegex"
                      >
                        <b-form-input
                          id="br-holder-name"
                          v-model="trademarkForm.holderName"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.intellectualProperty.formTrademarks.placeholder.enterHolderName')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <hr>
                    <b-button
                      class="float-right ml-1"
                      variant="primary"
                      pill
                      :style="colorPrimaryBtn"
                      type="submit"
                    >
                      {{ $t( 'buttons.save') }}
                    </b-button>
                    <b-button
                      class="float-right"
                      variant="secondary"
                      pill
                      @click="$emit('change-type-view')"
                    >
                      {{ $t('buttons.cancel') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
          <b-col
            v-else
            cols="12"
            class="i-height-div"
          />
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapActions, mapGetters } from 'vuex'
import { trademarkTypes, requestTypes } from '@/services/catalogsService'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, min, max, nameRegex, between, clubNameRegex } from '@validations'
import { BFormGroup, BForm, BFormInput, BFormDatepicker, BButton, VBTooltip } from 'bootstrap-vue'
import { Cropper } from 'vue-advanced-cropper'
import { destroyMediaFile } from '@/services/mediaFileService'
import trademarkService from '@/services/trademarkService'
import UploadFile from '@/views/member/components/UploadFile.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import 'vue-advanced-cropper/dist/style.css'
import SweetAlertAnimation from '@/views/extensions/sweet-alert/SweetAlertAnimation.vue'

export default {
  components: {
    UploadFile,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BFormDatepicker,
    Cropper,
    vSelect,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    actions: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      pathImg: '',
      image: null,
      updated: null,
      todayTmk: new Date(),
      backupImage: null,
      croppedImage: null,
      applyOverlay: false,
      applyDeleteFileI: false,
      previousFileHashI: null,
      applyDeleteFileC: false,
      previousFileHashC: null,
      trademarkTypeList: [],
      requestTypeList: [],
      auxFile: [],
      trademarkForm: {
        id: null,
        clubId: null,
        requestTypeId: null,
        trademarkTypeId: null,
        classNumber: null,
        classDescription: null,
        denomination: null,
        registerNumber: null,
        dateOfConcession: null,
        effectiveDate: null,
        applicationForTheRegistrationFile: null,
        applicationForTheRegistrationFileId: null,
        holderName: null,
        imageFile: require('@/assets/images/generic/picture.jpg'),
        imageFileId: null,
        status: 1,
      },

      /* validation */
      required,
      integer,
      min,
      max,
      nameRegex,
      between,
      clubNameRegex,
    }
  },

  computed: {
    ...mapGetters({ colorPrimaryBtn: 'colorPrimaryBtn' }),

    requestTypes() {
      const list = this.requestTypeList.map(c => ({ id: c.hash, name: c.name }))
      return list
    },

    trademarkTypes() {
      const list = this.trademarkTypeList.map(c => ({ id: c.hash, name: c.name }))
      return list
    },

    minDateEff() {
      return moment(this.trademarkForm.dateOfConcession).format()
    },
  },

  watch: {
    '$i18n.locale': function () {
      this.fetchRequestTypes()
      this.fetchTrademarkTypes()
    },

    'trademarkForm.dateOfConcession': function (newValue, oldValue) {
      if (oldValue != null && (newValue != oldValue || newValue == null || newValue == '')) {
        this.trademarkForm.effectiveDate = null
      }
    },
  },

  mounted() {
    this.assignTrademark()
  },

  methods: {
    validatorFileSize,
    validatorFileType,

    ...mapActions({ mediaFileUpload: 'mediaFile/upload' }),

    fetchTrademarkTypes() {
      trademarkTypes().then(({ data }) => {
        this.trademarkTypeList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchRequestTypes() {
      requestTypes().then(({ data }) => {
        this.requestTypeList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    assignTrademark() {
      this.fetchRequestTypes()
      this.fetchTrademarkTypes()

      if (this.actions.name == 'edit') {
        this.applyOverlay = true
        trademarkService.getTrademarkInfo(this.actions.trademarkId).then(({ data }) => {
          const trademarkData = data.data
          this.trademarkForm.id = trademarkData.hash
          this.trademarkForm.clubId = trademarkData.club_hash
          this.trademarkForm.requestTypeId = trademarkData.request_type_hash
          this.trademarkForm.trademarkTypeId = trademarkData.trademark_type_hash
          this.trademarkForm.classNumber = trademarkData.class_number
          this.trademarkForm.classDescription = trademarkData.class_description
          this.trademarkForm.denomination = trademarkData.denomination
          this.trademarkForm.registerNumber = trademarkData.register_number
          this.trademarkForm.dateOfConcession = trademarkData.date_of_concession
          this.trademarkForm.effectiveDate = trademarkData.effective_date
          this.trademarkForm.applicationForTheRegistrationFile = trademarkData.application_media_file
          this.trademarkForm.applicationForTheRegistrationFileId = trademarkData.application_media_file.hash
          this.trademarkForm.holderName = trademarkData.holder_name
          this.trademarkForm.imageFile = trademarkData.path + trademarkData.image_media_file.url_relative
          this.trademarkForm.imageFileId = trademarkData.image_media_file.hash
          this.trademarkForm.status = trademarkData.status
          this.pathImg = trademarkData.path
          this.applyOverlay = false
        }).catch(error => {
          this.applyOverlay = false
          this.responseCatch(error)
        })
      }
    },

    inputImageRenderer() {
      this.getFileInBase64(this.$refs.refInputCover, base64 => {
        this.croppedImage = base64
      })
    },

    getFileInBase64(pCover, callback) {
      const auxCoverImage = pCover.files[0]
      const fileReader = new FileReader()

      fileReader.addEventListener(
        'load',
        () => {
          callback(fileReader.result)
        }, false,
      )

      if (auxCoverImage) {
        fileReader.readAsDataURL(auxCoverImage)
      }
    },

    change({ coordinates, canvas }) {
      this.coordinates = coordinates
      this.image = canvas.toDataURL()
    },

    cancelAction() {
      this.croppedImage = null
      this.image = null
    },

    assignCover() {
      const auxImage = this.dataURLtoFile(this.image, `cover_${this.trademarkForm.denomination}.png`)
      this.backupImage = auxImage

      if (this.actions.name == 'edit') {
        this.saveFileUpload(this.backupImage)
      }

      this.trademarkForm.imageFile = this.image
      this.updated = 'image'
      this.croppedImage = null
      this.image = null
    },

    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])

      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },

    async saveFileUpload(fileData) {
      const formData = new FormData()

      formData.append('file', fileData)
      formData.append('folder', 'clubs')
      formData.append('club_id', this.trademarkForm.clubId)

      await this.mediaFileUpload(formData).then(response => {
        const { data } = response.data
        this.assignFileDinamic(data, 1)
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    saveTrademarkForm() {
      this.trademarkForm.applicationForTheRegistrationFile = this.actions.name == 'edit' ? this.trademarkForm.applicationForTheRegistrationFile : this.auxFile
      console.log('aqui', this.$refs.trademarkRules.validate())
      this.$refs.trademarkRules.validate().then(success => {
        if (success) {
          if (this.actions.name == 'edit') {
            const formData = {
              id: this.actions.trademarkId,
              club_id: parseInt(this.actions.clubId, 10),
              request_type_id: this.trademarkForm.requestTypeId,
              trademark_type_id: this.trademarkForm.trademarkTypeId,
              class_number: this.trademarkForm.classNumber,
              class_description: this.trademarkForm.classDescription,
              denomination: this.trademarkForm.denomination,
              register_number: this.trademarkForm.registerNumber,
              date_of_concession: this.trademarkForm.dateOfConcession,
              effective_date: this.trademarkForm.effectiveDate,
              application_file: this.trademarkForm.applicationForTheRegistrationFile,
              application_file_id: this.trademarkForm.applicationForTheRegistrationFileId,
              holder_name: this.trademarkForm.holderName,
              image_file: this.trademarkForm.imageFile,
              image_file_id: this.trademarkForm.imageFileId,
              status: this.trademarkForm.status ? 1 : 0,
            }

            trademarkService.updateTrademark(formData).then(({ data }) => {
              this.responseSuccessUpdate(data.message)
              if (this.applyDeleteFileI || this.applyDeleteFileC) {
                this.deleteFileX()
              } else {
                this.$emit('change-type-view')
              }
            }).catch(error => {
              this.responseCatch(error)
            })
          } else {
            const formData = new FormData()
            formData.append('club_id', parseInt(this.actions.clubId, 10))
            formData.append('request_type_id', this.trademarkForm.requestTypeId)
            formData.append('trademark_type_id', this.trademarkForm.trademarkTypeId)
            formData.append('class_number', this.trademarkForm.classNumber)
            formData.append('class_description', this.trademarkForm.classDescription)
            formData.append('denomination', this.trademarkForm.denomination)
            formData.append('register_number', this.trademarkForm.registerNumber)
            formData.append('date_of_concession', this.trademarkForm.dateOfConcession)
            formData.append('effective_date', this.trademarkForm.effectiveDate)
            formData.append('application_for_the_registration_file', this.trademarkForm.applicationForTheRegistrationFile)
            formData.append('holder_name', this.trademarkForm.holderName)
            formData.append('image_file', this.backupImage)
            formData.append('status', this.trademarkForm.status)

            trademarkService.storeTrademark(formData).then(({ data }) => {
              this.responseSuccessCreate(data.message)
              this.$emit('change-type-view')
            })
          }
        } else {
          const arrayErrors = Object.values(this.$refs.trademarkRules.errors)
          let text = ''

          arrayErrors.forEach(value => {
            if (value.length) {
              text += `<li style="list-style:none; font-size:13px;">${value[0]}</li>`
            }
          })

          this.$swal({
            title: '¡Error!',
            icon: 'error',
            html:
              `<ul>${text}</ul>`,
            showConfirmButton: true,
            confirmButtonText: `${this.$t('generic.accept')}`,
            confirmButtonColor: this.colorRGB,
            customClass: {
              content: 'text-left',
            },
          })
        }
      })
    },

    assignFileDinamic(responseFile, opt) {
      if (opt == 1) {
        if (this.trademarkForm.imageFileId == null) {
          this.trademarkForm.imageFileId = responseFile.hash
        } else {
          this.previousFileHashI = this.trademarkForm.imageFileId
          this.applyDeleteFileI = true
          this.trademarkForm.imageFileId = responseFile.hash
        }
      } else {
        if (this.trademarkForm.applicationForTheRegistrationFileId == null) {
          this.trademarkForm.applicationForTheRegistrationFile = responseFile
          this.trademarkForm.applicationForTheRegistrationFileId = responseFile.hash
        } else {
          this.previousFileHashC = this.trademarkForm.applicationForTheRegistrationFileId
          this.applyDeleteFileC = true
          this.trademarkForm.applicationForTheRegistrationFile = responseFile
          this.trademarkForm.applicationForTheRegistrationFileId = responseFile.hash
        }
      }
    },

    deleteFileX() {
      let updateImg = false
      let updateC = false

      if (this.applyDeleteFileI) {
        updateImg = new Promise((resolve, reject) => {
          destroyMediaFile(this.previousFileHashI).then(response => {
            resolve (true)
          }).catch(error => {
            this.responseCatch(error)
            reject (false)
          })
        })
      } else updateImg = true

      if (this.applyDeleteFileC) {
        updateC = new Promise((resolve, reject) => {
          destroyMediaFile(this.previousFileHashC).then(response => {
            resolve(true)
          }).catch(error => {
            this.responseCatch(error)
            reject(false)
          })
        })
      } else updateC = true

      if (updateImg && updateC) {
        this.$emit('change-type-view')
      }
    },

    fileValidation(event) {
      if (event.target.file !== 'undefined') {
        const fileX = event.target.files[0]
        // const validSize = this.validatorFileSize(fileX)
        const validType = this.validatorFileType(fileX)

        // if (!validSize) {
        //   this.$refs['file-application-input'].reset()
        //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
        // }

        if (!validType) {
          this.$refs['file-application-input'].reset()
          this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
        }
      } else this.$refs['file-application-input'].reset()
    },
  },
}
</script>

<style scoped>
.i-demo-img {
  border-radius: 0.358rem;
  width: 100%;
  object-fit: contain;
}

.cropper {
  height: 360px;
  background: #DDD;
}

.i-translate-avatar-cl {
  transform: translate(0px, 105px);
}

.i-display-elements {
  display: inline-block;
  position: relative;
}

.i-display-elements p{
  position: absolute;
  bottom: 100px;
  right: 23px;
}
</style>
